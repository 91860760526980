import * as React from "react"
import logo from "./../images/logo.svg";

// markup
const Footer = () => {
    return (
        <footer className="py-16 lg:py-24 bg-white">
            <div className="max-w-screen-xl px-4 lg:px-12 mx-auto">
                <div className="grid lg:grid-cols-6 gap-12 lg:gap-24">
                    <div className="col-span-2">
                        <a href="https://www.bergify.com" className="flex mb-6">
                            <img src={logo} className="h-8 mr-3" alt="Bergify Logo" />
                            <span className="self-center text-lg font-semibold">Bergify</span>
                        </a>
                        <p className="text-gray-600">Bergify is a Wordpress block theme suitable for businesses and offering Full-Site Editing.</p>
                        <div class="flex pt-3">
                            <a href="https://www.instagram.com/bergify_/" class="p-3 pl-0 m-3 ml-0" target="_blank">
                                <svg role="img" class="text-gray-700 h-8 mb-5" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><title>Instagram</title><path stroke-linecap="round" stroke-linejoin="round" d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"></path></svg>
                            </a>
                            <a href="https://www.facebook.com/bergify.official" class="p-3 m-3" target="_blank">
                                <svg role="img" class="text-gray-700 h-8 mb-5" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><title>Facebook</title><path stroke-linecap="round" stroke-linejoin="round" d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"></path></svg>
                            </a>
                            <a href="https://twitter.com/bergify_" class="p-3 m-3" target="_blank">
                                <svg role="img" class="text-gray-700 h-8 mb-5" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><title>Twitter</title><path stroke-linecap="round" stroke-linejoin="round" d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"></path></svg>
                            </a>

                            <a href="https://www.youtube.com/@Bergify_" class="p-3 m-3" target="_blank">
                                <svg role="img" class="text-gray-700 h-8 mb-5" fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><title>YouTube</title><path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"/></svg>

                            </a>

                        </div>
                    </div>
                    <div>
                        <h3 className="text-gray-400 text-sm font-semibold uppercase mb-6">Get Bergify</h3>
                        <ul>
                            <li className="mb-4">
                                <a href="https://wordpress.org/themes/bergify/" target="_blank" rel="noreferrer" className="text-gray-600 font-normal hover:underline">Free Download</a>
                            </li>
                            <li className="mb-4">
                                <a href="/pro/" className="text-gray-600 font-normal hover:underline">PRO version</a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-gray-400 text-sm font-semibold uppercase mb-6">Support</h3>
                        <ul>
                            <li className="mb-4">
                                <a href="https://demo.bergify.com" target="_blank" rel="noreferrer" className="text-gray-600 font-normal hover:underline">Demo / Preview</a>
                            </li>
                            <li className="mb-4">
                                <a href="https://docs.bergify.com" target="_blank" rel="noreferrer" className="text-gray-600 font-normal hover:underline">Documentation</a>
                            </li>
                            <li className="mb-4">
                                <a href="https://blog.bergify.com" target="_blank" rel="noreferrer" className="text-gray-600 font-normal hover:underline">Blog</a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-gray-400 text-sm font-semibold uppercase mb-6">Legal</h3>
                        <ul>
                            <li className="mb-4">
                                <a href="/privacy-policy/" className="text-gray-600 font-normal hover:underline">Privacy Policy</a>
                            </li>
                            <li className="mb-4">
                                <a href="/terms-conditions/" className="text-gray-600 font-normal hover:underline">Terms & Conditions</a>
                            </li>
                            <li className="mb-4">
                                <a href="/license/" className="text-gray-600 font-normal hover:underline">License</a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-gray-400 text-sm font-semibold uppercase mb-6">Contact</h3>
                        <p className="text-gray-600">HorMart s.r.o.<br/>Puskinova 8<br/>Prostejov<br/>79601<br/>Czech Republic</p>
                        <ul>
                            <li className="my-4">
                                <a href="mailto:info@bergify.com" target="_blank" className="text-gray-600 font-normal hover:underline">E-mail</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <hr className="bg-gray-200 my-8 lg:my-12" />
                <span className="block text-center text-gray-600 font-">© 2022-{new Date().getFullYear()} Design by <a href="https://flowbite.com" target="_blank" rel="noreferrer">Flowbite</a> and developed by <a href="https://www.hormart.cz" target="_blank" rel="noreferrer">HorMart</a>. All Rights Reserved.</span>
            </div>
        </footer>
    )
}

export default Footer
