import React from "react"
import { Link } from "gatsby"
// import { AmplifySignOut } from "@aws-amplify/ui-react";

import logo from "./../images/logo.svg";

class Navbar extends React.Component {
  listener = null;
  state = {
    nav: false,
    collapse: true
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  // componentWillUnmount() {
  //   window.removeEventListener('scroll');
  // }

  handleScroll = () => {
    if (window.pageYOffset > 400) {
      if (!this.state.nav) {
        this.setState({ nav: true });
      }
    } else {
      if (this.state.nav) {
        this.setState({ nav: false });
      }
    }

  }

  toggleCollapse = () => {
    this.setState({ collapse: !this.state.collapse })
  }

  render() {
    return (
      <header>
        <nav className={`py-4 w-full bg-white transition duration-150 ${this.state.nav && 'fixed left-0 top-0 shadow-md z-30 navbar-top'}`}>

          <div className="max-w-screen-xl px-4 lg:px-12 mx-auto flex flex-col lg:flex-row lg:justify-between lg:items-center">
            <div className="flex justify-between">
              <Link to="/" className="flex">
                <img src={logo} className="h-8 mr-3" alt="Bergify Logo" />
                <span className="self-center text-xl font-semibold whitespace-nowrap">Bergify</span>
              </Link>
              <button onClick={() => this.toggleCollapse()} className="lg:hidden"><svg className="w-6 h-6 text-gray-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg></button>
            </div>
            <ul className={`flex flex-col lg:flex-row lg:self-center py-6 lg:py-0 collapse ${this.state.collapse && 'collapsed'}`}>
              <li className="lg:px-6 mb-3 lg:mb-0">
                <a href="https://demo.bergify.com/" target="_blank" className="font-medium text-gray-900 hover:text-blue-600">Demo / Preview</a>
              </li>
              <li className="lg:px-6 mb-3 lg:mb-0">
                <a href="https://docs.bergify.com/" target="_blank" className="font-medium text-gray-900 hover:text-blue-600">Documentation</a>
              </li>
              <li className="lg:px-6 mb-3 lg:mb-0">
                <Link to="/pro/" className="font-medium text-gray-900 hover:text-blue-600">Upgrade to PRO</Link>
              </li>
            </ul>
            <div className={`lg:self-center mb-4 lg:mb-0 collapse ${this.state.collapse && 'collapsed'}`}>
              <Link to="https://wordpress.org/themes/bergify/" target="_blank" className="text-gray-600 bg-white-100 hover:bg-gray-100 hover:text-blue-600 border border-gray-200 font-semibold rounded-xl text-base px-6 py-3 lg:mx-6">Download</Link>
              <Link to="/pro/#pricing" className="text-white bg-gray-800 hover:bg-gray-900 font-semibold rounded-xl text-base px-6 py-3 lg:ml-6">Pricing & FAQ</Link>
            </div>  
          </div>
        
        </nav>
      </header>
    );
  }
}
export default Navbar
