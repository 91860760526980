import * as React from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"

import page1 from "./../images/carousel/pro-landing.png"
import page2 from "./../images/carousel/pro-pricing.png"
import page3 from "./../images/carousel/pro-contact.png"
import page4 from "./../images/carousel/pro-team.png"


const ImageCarousel = () => {
  return (
    <Carousel showThumbs={false} showArrows={true} showStatus={false} showIndicators={true}>
      <div>
        <img src={page1} alt="The Landing Page" />
      </div>
      <div>
        <img src={page2} alt="The Pricing Page" />
      </div>
      <div>
        <img src={page3} alt="The Contact Page" />
      </div>
      <div>
        <img src={page4} alt="The Team Page" />
      </div>
      
    </Carousel>
  );
};

export default ImageCarousel;
